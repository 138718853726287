export class Result<T> {
    private _value?: T;
    private _errors?: Array<string>;

    constructor(value?: T, errors?: Array<string>) {
        this._value = value;
        this._errors = errors;
    }

    value(): T {
        if (this._errors) {
            throw Error("Can't get value of result when result has errors");
        }
        return this._value!;
    }

    success() {
        return !this.hasErrors();
    }

    error() {
        return this._errors;
    }

    hasErrors() {
        return this._errors !== undefined;
    }
}
